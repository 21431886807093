.sign-up {
  display: flex;
  flex-direction: column;
  width: 380px;

  .title {
    margin: 10px 0;
    text-decoration: underline;
  }
}


@media (max-width: 800px) {
  .sign-up {
    width: 100%;
    justify-content: center;
  }
}