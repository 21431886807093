.sign-in {
  width: 380px;
  display: flex;
  flex-direction: column;

  .title {
    margin: 10px 0;
    text-decoration: underline;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 800px) {
  .sign-in {
    width: 100%;
  }
  .buttons {
    flex-direction: column;
  }
}
